<template>
    <div class="row justify-content-center">
        <div class="col-md-5">
            <h3 class="text-center">Update</h3>
            <form @submit.prevent="onUpdateForm">
                <div class="form-group">
                    <input type="text" class="form-control" v-model="color.name" required>
                </div>

                <div class="form-group">
                    <button class="btn btn-primary btn-block">Save</button>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
    import { db } from '../firebaseDb';

    export default {
        data() {
            return {
                color: {
                },
                originalPers: "",
                serviceid: "",
                service: {},
                currentTenant: "",
            }
        },
        created() {
            this.currentTenant = this.$tenant;
            let dbRef = db.collection('colors').doc(this.$route.params.id);
            dbRef.get().then((doc) => {
                this.color = doc.data();
                this.originalPers = doc.data().name;
            }).catch((error) => {
                console.log(error)
            })
        },
        methods: {
            onUpdateForm(event) {
                event.preventDefault()
                
                if(this.color.name != this.originalPers) {
                    //Search services for services with name and edit them?
                db.collection('caskets').onSnapshot((snapshotChange) => {
                snapshotChange.forEach((doc) => {
                    console.log(this.originalPers)
                    if(doc.data().color == this.originalPers) {
                        if(doc.data().tenant == this.currentTenant) {
                            this.service = doc.data();
                            this.serviceid = doc.id;
                            this.service.color = this.color.name;
                            db.collection('caskets').doc(this.serviceid)
                            .update(this.service).then(() => {
                                console.log("Service successfully updated!");
                            }).catch((error) => {
                                console.log(error);
                            });
                        }
                    }
                })
                });
                }
                
                db.collection('colors').doc(this.$route.params.id)
                .update(this.color).then(() => {
                    console.log("Updated!");
                    this.$router.push('/colorlist')
                }).catch((error) => {
                    console.log(error);
                });
            }
        }
    }
</script>